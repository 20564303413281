@import "~@coreui/coreui/scss/coreui.scss";
@import "react-confirm-alert/src/react-confirm-alert.css";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");
@import "react-toastify/dist/ReactToastify.css";

$primary: #0080ff;

body {
  font-family: "Ubuntu", sans-serif;
}

.c-sidebar {
  color: #fff;
  background: #3c51db;
}

.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: $primary;
}

.c-sidebar .c-sidebar-minimizer {
  background-color: $primary;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: $primary;
}

.c-active {
  background: #6872b3;
}

.required-class:after {
  content: "*";
  color: red;
  margin-left: 3px;
  font-size: 14px;
}

.custom-dd-input {
  border: none;
}

.custom-dd-input::after {
  right: 3%;
  top: 15px;
  position: absolute;
}

.pac-container {
  z-index: 10000 !important;
}
.react-confirm-alert-body {
  color: #3c51db;
}

.react-confirm-alert-button-group > button {
  background: #3c51db;
}

.pointer {
  cursor: pointer;
}

.card-header-title-font {
  font-size: 1.4rem;
}

.option-box .card-body {
  padding: 10px 5px !important;
}

.option-box .card-body .small {
  padding: 20px 0px !important;
}

.option-disabled {
  cursor: not-allowed;
  text-decoration: line-through;
  background-color: #383edb;
  background-image: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 5px,
    #fff 5px,
    #fff 10px
  );
}

.border-bottom-dark {
  border-bottom: 1px dashed #000;
}

@media print {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8%;
  }
  .col-md-2 {
    width: 16%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33%;
  }
  .col-md-5 {
    width: 42%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58%;
  }
  .col-md-8 {
    width: 66%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83%;
  }
  .col-md-11 {
    width: 92%;
  }
  .col-md-12 {
    width: 100%;
  }
}
.draggable-item {
  padding: 5px;
  border: 1px solid;
  margin: 5px 0px;
  font-weight: 700;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
}
.custom-mdd-input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem !important;
}

.custom-mdd-input::after {
  right: 3%;
  top: 15px;
  position: absolute;
}

.multi-dropdown-container {
  width: 100%;
  display: block;
  overflow: scroll;
}

.multi-dropdown-tag {
  background: $primary;
  color: #fff;
  padding: 0.2em 0.35em;
  border-radius: 0.25em;
  margin-right: 0.5em;
}

.subject-count:hover {
  background-color:dodgerblue;
  color: #fff
}

.droppable {
  background: #1e2978;
  box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
}

.dropdown-menu-height {
  overflow: scroll;
  max-height: 250px;
}

.sidemenu-modal-body, .long-modal-body {
  max-height: 65vh;
  overflow: scroll;
}

.icon-modal-body {
  max-height: 30vh;
  overflow: scroll;
}

.is-disable {
  opacity: 0.5;
}

.time-table-class-view .teacher {
  font-size: 12px;
  font-weight: 700;
  color: #321fdb;
}

.blur {
  filter: blur(10px);
}